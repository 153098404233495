import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const notification = this.element
    const timeout = Number(notification.dataset['timeout'])

    notification.style.transition= 'opacity 2.5s ease-in-out'
    notification.style.opacity = '0'

    setTimeout(() => {
      notification.style.display = 'none'
    }, timeout)
  }
}
