import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selects', 'sku', 'regularPrice', 'promoPrice']

  connect() {}

  select_spec() {
    const productSpecs = JSON.parse(this.element.dataset.productSpecs)
    const index = this.selectsTarget.selectedIndex
    const sku = this.selectsTarget.options[index].value

    const selectedEl = productSpecs.filter((el) => {
      return el.sku == sku
    })[0]

    const promoPrice = selectedEl.promo_price
    const regularPrice = selectedEl.regular_price

    this.skuTarget.innerHTML = selectedEl.sku
    this.promoPriceTarget.innerHTML = promoPrice ? promoPrice : regularPrice
    this.regularPriceTarget.innerHTML = promoPrice ? regularPrice : ''
  }
}
