import { Controller } from 'stimulus'
import fileUpload from '../src/backend/javascripts/modules/uppy_fileupload'

export default class extends Controller {
  connect() {
    const fileInputs = document.querySelectorAll('input[type="file"]')
    if (!fileInputs) { return }

    fileInputs.forEach(input => {
      fileUpload(input, input.closest('.image-field'))
    })
  }
}
