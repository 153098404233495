import { Controller } from 'stimulus'
import fileUpload from '../src/backend/javascripts/modules/uppy_fileupload'

export default class extends Controller {
  static targets = ['links', 'template']

  connect() {
    const fileInputs = document.querySelectorAll('input[type="file"]')
    if (!fileInputs) { return }

    fileInputs.forEach(input => {
      fileUpload(input, input.closest('.nested-field'))
    })
  }

  add_field(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/new_record/g, new Date().getTime())
    const wrapper = this.linksTarget.closest('[data-controller]')

    wrapper.insertAdjacentHTML('beforeend', content)

    const fields = wrapper.querySelectorAll('.nested-field')
    const lastField = fields[fields.length - 1]
    const fileInput = lastField.querySelector('input[type="file"]')

    fileUpload(fileInput, lastField)
  }

  remove_field(event) {
    event.preventDefault()

    const fields = event.target.closest('.nested-field')

    if (fields.dataset.newRecord == 'true') {
      const imageSrc = fields.querySelector('.image-preview > img').src
      const navImage = document.querySelector(`img[src="${imageSrc}"]`)

      if (imageSrc && navImage) {
        navImage.closest('.nav-item').remove()
      }

      fields.remove()
      return
    }

    fields.querySelector('input[name*=\'_destroy\']').value = 1

    const tabPane = event.target.closest('.tab-pane')
    const navLink = document.querySelector(`a[href="#${tabPane.id}"]`)

    tabPane.style.display = 'none'
    navLink.parentElement.style.display = 'none'
  }
}
