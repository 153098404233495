import Core from '@uppy/core'
import FileInput from '@uppy/file-input'
import AwsS3 from '@uppy/aws-s3'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

function fileUpload(fileInput, wrapper) {
  const uppy = Core({ autoProceed: true })
  const adminPath = window.location.pathname.split('/')[1]

  const formGroup = fileInput.parentNode
  const hiddenInput = wrapper.querySelector('[data-upload]')
  const imagePreview = wrapper.querySelector('.image-preview img')

  fileInput.remove()

  uppy
    .use(FileInput, {
      target: formGroup,
    })
    .use(ThumbnailGenerator)
    .use(AwsS3, {
      limit: 5,
      getUploadParameters(file) {
        let url = `/${adminPath}/s3/params?filename=${file.name}&type=${file.type}`

        return fetch(url, {
          method: 'get',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json'
          }
        }).then(response => response.json())
      }
    })

  uppy.on('thumbnail:generated', (_file, preview) => {
    imagePreview.src = preview
  })

  uppy.on('upload-success', (file) => {
    const uploadedFileData = {
      id: file.meta['key'].match(/cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    hiddenInput.value = JSON.stringify(uploadedFileData)
  })
}

export default fileUpload
