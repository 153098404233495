import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['links', 'template']

  connect() {}

  add_field(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/new_record/g, new Date().getTime())
    let wrapper = this.linksTarget.closest('[data-controller]')

    wrapper.insertAdjacentHTML('beforeend', content)
  }

  remove_field(event) {
    event.preventDefault()

    let fields = event.target.closest('.nested-field')

    if (fields.dataset.newRecord == 'true') {
      fields.remove()
      return
    }

    fields.querySelector('input[name*=\'_destroy\']').value = 1
    fields.closest('.nested-item').style.display = 'none'
  }
}
