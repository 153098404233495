import { Controller } from 'stimulus'
import fileUpload from '../src/backend/javascripts/modules/uppy_fileupload'
export default class extends Controller {
  static targets = ['fields']

  connect() {
    this.upload_image()
  }

  select_style(event) {
    const templateName = event.target.selectedOptions[0].value
    const template = document.getElementById(templateName)
    const wrapper = this.fieldsTarget

    wrapper.innerHTML = template.innerHTML

    this.upload_image()
  }

  upload_image() {
    const fileInputs = document.querySelectorAll('input[type="file"]')

    if (!fileInputs) { return }

    fileInputs.forEach(input => {
      fileUpload(input, input.closest('.image-field'))
    })
  }
}
